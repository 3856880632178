import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import Eurotop from '../../src/Icons/Eurotop';
import Foam from '../../src/Icons/Foam';
import GelMemoryFoam from '../../src/Icons/GelMemoryFoam';
import Hybrid from '../../src/Icons/Hybrid';
import Innerspring from '../../src/Icons/Innerspring';
import Latex from '../../src/Icons/Latex';
import MemoryFoam from '../../src/Icons/MemoryFoam';
import Pillowtop from '../../src/Icons/Pillowtop';
import PocketCoils from '../../src/Icons/PocketCoils';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Icon from 'mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <Eurotop width={30} height={30} />\n  <Foam width={30} height={30} />\n  <GelMemoryFoam width={30} height={30} />\n  <Hybrid width={30} height={30} />\n  <Innerspring width={30} height={30} />\n  <Latex width={30} height={30} />\n  <MemoryFoam width={30} height={30} />\n  <Pillowtop width={30} height={30} />\n  <PocketCoils width={30} height={30} />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      Eurotop,
      Foam,
      GelMemoryFoam,
      Hybrid,
      Innerspring,
      Latex,
      MemoryFoam,
      Pillowtop,
      PocketCoils,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Eurotop width={30} height={30} mdxType="Eurotop" />
    <Foam width={30} height={30} mdxType="Foam" />
    <GelMemoryFoam width={30} height={30} mdxType="GelMemoryFoam" />
    <Hybrid width={30} height={30} mdxType="Hybrid" />
    <Innerspring width={30} height={30} mdxType="Innerspring" />
    <Latex width={30} height={30} mdxType="Latex" />
    <MemoryFoam width={30} height={30} mdxType="MemoryFoam" />
    <Pillowtop width={30} height={30} mdxType="Pillowtop" />
    <PocketCoils width={30} height={30} mdxType="PocketCoils" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      